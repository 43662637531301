import { AddShoppingCart, Favorite, Person, Search } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
export default function Header() {
  return (
    <header>
      <nav>
        <img src={logo} className="logo" alt="" />
        <div className="search-bar-section">
          <input
            type="text"
            className="search-bar"
            placeholder="Search for products (e.g. apple, oil, fish)"
          />
          <Search />
        </div>
        <div className="auth-section">
          <Person />
          <Link to="/cart">
            <AddShoppingCart />
          </Link>

          <Favorite />
        </div>
      </nav>
      <div className="bottom-nav-bar">
        <Link to="/" className="nav-items active">
          Home
        </Link>
        <a href="/about" className="nav-items">
          About
        </a>
        <a href="/vendors" className="nav-items">
          Vendors
        </a>
        <a href="/contact" className="nav-items">
          Contact
        </a>
        <a href="/product" className="nav-items">
          Products
        </a>
      </div>
    </header>
  );
}

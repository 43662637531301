import "./App.css";
import { AddShoppingCart, LocationOn, Call, Mail } from "@mui/icons-material";
import bannerBottom from "./assets/images/banner-bottom.jpg";
import banner from "./assets/images/banner.jpg";
import cat1 from "./assets/images/cat1.jpg";
import logo from "./assets/images/logo.png";
import React from "react";
import { httpPOST } from "./Api/Api";
import Header from "./Component/Header";
import { connect } from "react-redux";
// import { pageActionTypes } from "./Configs/actionTypes";
import { addProductDetails } from "./Store/Actions/pageActions";
import { Link } from "react-router-dom";
function App(props) {
  const [categories, setCategory] = React.useState([]);
  const [products, setProduct] = React.useState([]);
  React.useEffect(() => {
    httpPOST("/open/crm-multi-label-data-get", {
      sourceLabels: ["category", "product"],
    }).then((res) => {
      let { category, product } = res.data;
      setCategory(category || []);
      setProduct(product || []);
      props.addProductDetails(product || []);
    });
  }, []);
  return (
    <div className="body">
      <Header />
      <main>
        <section>
          <div
            className="banner"
            style={{
              height: "30rem",
              background: `url('${banner}') no-repeat`,
              backgroundSize: "cover",
            }}
          >
            <div className="banner-title">Get Exotic Offers</div>
            <div className="banner-subtitle">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Reiciendis animi dolorem quo odio, fuga perspiciatis recusandae
              impedit quasi facere, iusto culpa voluptate et mollitia sed ad!
              Quis necessitatibus natus dolorem?
            </div>
          </div>
        </section>
        <div className="section">
          <div className="section-title">Featured Categories</div>
          <div className="section-body grid-content">
            {categories.map((item) => (
              <div className="item-card">
                <img src={cat1} className="product-image" alt="" />
                <div className="title">{item.categoryName}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="section">
          <div className="section-title">Popular Products</div>
          <div className="section-body grid-content">
            {products.map((item) => (
              <div className="item-card text-align-left">
                <img src={cat1} className="product-image" alt="" />
                <div className="product-description">
                  <div className="product-head">{item.productCategoryName}</div>
                  <div className="title">
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/product/${item.id}`}
                    >
                      {item.productName}
                    </Link>
                  </div>
                  <div className="description">Rating 4.3</div>
                  <div className="subtitle">by Akash Chourasia</div>
                  <div className="product-footer">
                    <div className="price">
                      <span className="actual-price">${item.productMRP}</span>
                      <span className="mrp-price">${item.productMRP} </span>
                    </div>
                    <div className="add-to-cart">
                      <button className="btn-cart">
                        <AddShoppingCart />
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="section" id="subscribe">
          <div
            className="section-body banner"
            style={{
              height: "30rem",
              background: `#ffffff00 url('${bannerBottom}') no-repeat`,
              backgroundSize: "cover",
            }}
          >
            <div className="banner-title">
              Stay home & get your daily needs from our shop
            </div>
            <div className="banner-subtitle">
              Start you'r shopping with CG Mart
            </div>
            <div className="subscribe-section">
              <input type="text" className="search-bar" />
              <button className="btn">Subscribe</button>
            </div>
          </div>
        </div>
        <section>
          <div className="section-body grid-content">
            <div className="kpi">
              <div className="title">Best Price & Offers</div>
              <div className="subtitle">Order $350 or more</div>
            </div>
            <div className="kpi">
              <div className="title">Free Delivery</div>
              <div className="subtitle">24/7 amazing services</div>
            </div>
            <div className="kpi">
              <div className="title">Great daily deal</div>
              <div className="subtitle">When you sign up</div>
            </div>
            <div className="kpi">
              <div className="title">Easy returns</div>
              <div className="subtitle">Within 30 days</div>
            </div>
          </div>
        </section>
        <footer className="grid-content grid-content-3">
          <div className="grid-item">
            <img src={logo} className="logo" alt="" />
            <div className="details">
              <LocationOn />
              <span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam
                ex totam porro dolores quam nulla ab
              </span>
            </div>
            <div className="details">
              <Call />
              0000-000-000
            </div>
            <div className="details">
              <Mail />
              demo@oxlide.co.in
            </div>
          </div>
          <div className="grid-item">
            <div className="title">Company</div>
            <div className="details">About Us</div>
            <div className="details">Delivery Information</div>
            <div className="details">Privacy Policy</div>
            <div className="details">Terms & Conditions</div>
            <div className="details">Contact Us</div>
            <div className="details">Support Center</div>
            <div className="details">Careers</div>
          </div>
          <div className="grid-item">
            <div className="title">Account</div>
            <div className="details">Sign In</div>
            <div className="details">View Cart</div>
            <div className="details">Wishlist</div>
            <div className="details">Track My Order</div>
          </div>
        </footer>
      </main>
    </div>
  );
}
const mapStateToProp = (state) => {
  return {
    product: state.product,
  };
};
export default connect(mapStateToProp, { addProductDetails })(App);

const axiosWithAuth = () => {
  let headers = {
    "Content-Type": "application/json",
  };
  return {
    baseURL: process.env.REACT_APP_BASE_URL, // Your API base URL
    headers: headers,
  };
};

export default axiosWithAuth;

import React from "react";
import "./ProductDetails.css";
import { Card } from "@mui/material";
import Header from "../../Header";

import cat1 from "../../../assets/images/cat1.jpg";
import { AddShoppingCart } from "@mui/icons-material";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { httpPOST } from "../../../Api/Api";
function P_ProductDetails(props) {
  const { crmId } = useParams();
  const [product, setProduct] = React.useState({});
  const [qty, setQty] = React.useState(1);
  React.useEffect(() => {
    // let tempProduct =
    //   (props &&
    //     props.product &&
    //     props.product.productDetails.filter((item) => item.id === crmId)) ||
    //   [];
    // if (tempProduct.length > 0) {
    //   setProduct(tempProduct[0]);
    // }
    setProductDetails();
  }, []);
  const setProductDetails = () => {
    httpPOST("/open/crm-multi-label-data-get", {
      sourceLabels: ["product"],
    }).then((res) => {
      let { product } = res.data;
      let tempProduct = (product || []).filter((item) => item.id === crmId);
      setProduct(tempProduct[0]);
    });
  };
  const handleAddToCart = () => {
    let cartItem = [];
    try {
      cartItem = JSON.parse(localStorage.getItem("cartItem"));
    } catch (e) {
      console.log("Something wrong");
    }
    let isAdded = false;
    if (cartItem && Array.isArray(cartItem)) {
      cartItem.forEach((item) => {
        if (item&&item.id === crmId) {
          isAdded = true;
          item.qty = Number(item.qty) + Number(qty);
        }
      });
    }
    if (!isAdded && product) {
      cartItem = [{ ...product, qty }];
    }
    localStorage.setItem("cartItem", JSON.stringify(cartItem));
  };
  return (
    <div className="body">
      <Header />
      <main>
        <div className="section">
          <Card className="p-2">
            <div className="heading-title">Product Details</div>
            <div className="product-details">
              <img src={cat1} className="product-image" alt="Product Image" />

              <div className="product-info">
                <h2 className="product-title">{product.productName}</h2>
                <p className="product-price">${product.productMRP}</p>
                <p className="product-description">
                  {product.productDescription}
                </p>

                <div className="quantity-select">
                  <label for="quantity">Quantity:</label>
                  <select
                    id="quantity"
                    value={qty}
                    onChange={(e) => {
                      setQty(e.currentTarget.value);
                    }}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
                <div className="btn btn-primary" onClick={handleAddToCart}>
                  Add To Cart
                </div>
                <p className="back-link">
                  <a href="/">Back to Home</a>
                </p>
              </div>
            </div>
          </Card>
        </div>

        <div className="section">
          <div className="section-title">Related Products</div>
          <div className="section-body grid-content">
            {props &&
              props.product &&
              props.product.productDetails
                .filter((item, index) => index < 5)
                .map((item) => (
                  <div className="item-card text-align-left">
                    <img src={cat1} className="product-image" alt="" />
                    <div className="product-description">
                      <div className="product-head">
                        {item.productCategoryName}
                      </div>
                      <div className="title">{item.productName}</div>
                      <div className="description">Rating 4.3</div>
                      <div className="subtitle">by Akash Chourasia</div>
                      <div className="product-footer">
                        <div className="price">
                          <span className="actual-price">
                            ${item.productMRP}
                          </span>
                          <span className="mrp-price">${item.productMRP} </span>
                        </div>
                        <div className="add-to-cart">
                          <button className="btn" onClick={handleAddToCart}>
                            <AddShoppingCart />
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </main>
    </div>
  );
}
const mapStateToProp = (state) => {
  return {
    product: state.product,
  };
};

export default connect(mapStateToProp)(P_ProductDetails);

import { combineReducers } from "redux";
import pageReducer from "./pageReducer";
import productReducer from "./productReducer";

const rootReducer = combineReducers({
  page: pageReducer,
  product: productReducer,
});

export default rootReducer;

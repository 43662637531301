import React from "react";
import "./Cart.css";
import Header from "../../Header";
import { Card } from "react-bootstrap";
import cat1 from "../../../assets/images/cat1.jpg";
export default function P_Cart() {
  const [cartItems, setCartItem] = React.useState([]);

  React.useEffect(() => {
    let cart = [];
    try {
      cart = JSON.parse(localStorage.getItem("cartItem"));
    } catch (e) {
      console.log("Something Wrong");
    }
    setCartItem(cart);
  });
  const handleTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      total += Number(item.qty) * Number(item.productMRP);
    });
    return total;
  };
  const handleRemoveItem = (id) => {
    let cart = [];
    try {
      cart = JSON.parse(localStorage.getItem("cartItem"));
    } catch (e) {
      console.log("Something Wrong");
    }
    cart =
      cart && Array.isArray(cart) ? cart.filter((item) => item.id !== id) : [];
    localStorage.setItem("cartItem", JSON.stringify(cart));
    setCartItem(cart);
  };
  return (
    <>
      <Header></Header>
      <main>
        <Card className="p-2">
          {" "}
          <h1>Cart Details</h1>
          {cartItems &&
            cartItems.map((item) => (
              <div class="cart-item">
                <div class="product-image">
                  <img src={cat1} alt="Product Image" />
                </div>
                <div class="product-info">
                  <h2 class="product-title">{item.productName}</h2>
                  <p class="product-price">${item.productMRP}</p>
                  <p class="product-quantity">Quantity: {item.qty}</p>
                  <p class="product-total">
                    Total: ${Number(item.qty) * Number(item.productMRP)}
                  </p>
                  <button
                    class="remove-button"
                    onClick={() => {
                      handleRemoveItem(item.id);
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          <div class="cart-total">
            <p>
              <strong>Cart Total:</strong> ${handleTotal()}
            </p>
            <button class="checkout-button">Proceed to Checkout</button>
          </div>
          <p class="back-link">
            <a href="/">Back to Home</a>
          </p>
        </Card>
      </main>
    </>
  );
}

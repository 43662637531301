import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import Header from "./Component/Header";
import P_About from "./Component/Page/App/P_About";
import P_ProductDetails from "./Component/Page/ProductPage/P_ProductDetails";
import P_Cart from "./Component/Page/Cart/P_Cart";

export default function RoutingEngine() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<App />}></Route>
          <Route path="/about" element={<P_About />}></Route>

          <Route path="/category/:crmId" exact element={<App />}></Route>
          <Route
            path="/product/:crmId"
            exact
            element={<P_ProductDetails />}
          ></Route>
          <Route path="/cart/" exact element={<P_Cart />}></Route>
          <Route
            path="*"
            element={
              <>
                <div
                  style={{
                    fontFamily: "Arial, sans-serif",
                    backgroundColor: "#f1f1f1",
                    color: "#333",
                    textAlign: "center",
                    padding: "50px",
                  }}
                >
                  <h1>404 - Page Not Found</h1>
                  <p>Oops! The page you are looking for could not be found.</p>
                  <p>
                    Please check the URL or go back to the{" "}
                    <a href="/">homepage</a>.
                  </p>
                </div>
              </>
            }
          ></Route>
        </Routes>
      </div>
    </Router>
  );
}

import React from "react";
import Header from "../../Header";

export default function P_About() {
  return (
    <>
      <Header></Header>
      <div style={{ padding: "4rem" }}>
        <h1 style={{ fontSize: "36px" }}>About Us</h1>
        <p style={{ fontSize: "18px", marginBottom: "20px" }}>
          Welcome to our e-commerce store! We're dedicated to providing you with
          the best online shopping experience.
        </p>
        <p style={{ fontSize: "18px", marginBottom: "20px" }}>
          Our mission is to offer a wide range of high-quality products at
          competitive prices. Whether you're looking for electronics, fashion,
          home goods, or any other category, we've got you covered.
        </p>
        <p style={{ fontSize: "18px", marginBottom: "20px" }}>
          At our e-commerce store, we believe in:
        </p>
        <ul style={{ fontSize: "18px", marginBottom: "20px" }}>
          <li>
            Customer Satisfaction: Your satisfaction is our top priority. We
            strive to deliver excellent customer service and ensure that you
            have a positive shopping experience.
          </li>
          <li>
            Quality Products: We source products from trusted suppliers and
            brands to ensure that you receive only the best quality items.
          </li>
          <li>
            Convenience: We understand the value of your time. Our user-friendly
            website is designed to make your shopping process smooth and
            hassle-free.
          </li>
          <li>
            Secure Transactions: We prioritize the security of your personal
            information and offer secure payment options to protect your
            financial data.
          </li>
        </ul>
        <p style={{ fontSize: "18px", marginBottom: "20px" }}>
          If you have any questions, feedback, or concerns, please don't
          hesitate to reach out to us. Our customer support team is here to
          assist you.
        </p>
        <p style={{ fontSize: "18px", marginBottom: "20px" }}>
          Thank you for choosing our e-commerce store for all your shopping
          needs!
        </p>
      </div>
    </>
  );
}

import { pageActionTypes, productActionTypes } from "../../Configs/actionTypes";

export const addPageDetails = (page) => {
  return {
    type: pageActionTypes.ADD_PAGE_DETAILS,
    payload: page,
  };
};
export const addProductDetails = (product) => {
  return {
    type: productActionTypes.ADD_PRODUCT_DETAILS,
    payload: product,
  };
};

import axios from "axios";
import axiosWithAuth from "./Api.helper";
export const httpPOST = async (url, payload, addOnHeader) => {
  try {
    const { baseURL, headers } = axiosWithAuth();
    let response = await axios.post(baseURL + url, payload, {
      headers: headers,
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    return { status: 500, data: [] };
  }
};
export const httpGET = async (url, addOnHeader) => {
  const { baseURL, headers } = axiosWithAuth();
  let response = await axios.get(baseURL + url, { ...headers, ...addOnHeader });
  return { status: response.status, data: response.data };
};
